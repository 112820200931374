import { GeneralInstantBookIcon } from '@outdoorsyco/bonfire';
import xor from 'lodash/xor';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMenuTriggerState } from 'react-stately';

import SuperhostBadgeLightSvg from '@/assets/superhost/superhost-badge-light.svg';
import { useAmenitiesFilterList } from '@/constants/amenitiesFilter';
import { EFilterCategories, ESearchFilters } from '@/constants/searchFilters';
import { allVehicleStyles } from '@/constants/vehicleDetails';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useReMountableElementRef } from '@/hooks/useReMountableElementRef';
import { useRouter } from '@/hooks/useRouter';
import { useSelectedCampground } from '@/hooks/useSelectedCampground';
import { applySearchFilter, setSelectedFilter } from '@/redux/modules/search';
import { EFilterMethod, setFormFilter } from '@/redux/modules/searchForm';
import { TRootState } from '@/redux/rootReducer';
import { setFilterVehiclesShow } from '@/redux/selectors/analytics-selectors';
import { getQueryParams } from '@/redux/selectors/queryParams';
import { getIsStationaryFilterTriggered } from '@/redux/selectors/search/searchResults';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import { EDeliveryOption } from '@/services/types/core/delivery.types';
import { TVehicleType } from '@/services/types/search/rentals/id';
import { getParamAsString } from '@/utility/queryParams';
import {
  amenitiesFromFilterFeatureArray,
  moreFromFilterFeatureArray,
} from '@/utility/searchFilters';

import { useFilterPillsCtx } from '../FilterPillsContext/FilterPillsContext';
import FilterPills from './FilterPills';
import FiltersPillsContentContainer from './FilterPillsContentContainer';

const FilterPillsContainer: React.FC = () => {
  const intl = useIntl();
  const router = useRouter();
  const { isMobile } = useBreakpoint();
  const { isAutocompleteOpen, hideFilter } = useFilterPillsCtx();
  const dispatch = useDispatch();
  const amenitiesItems = useAmenitiesFilterList(false);
  const filtersFromQuery = useSelector(getQueryParams);
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [dropdownIsOpened, setIsDropdownOpened] = useState(false);
  const { ref: triggerRef, setElement: setFieldElement } = useReMountableElementRef<HTMLElement>();

  const superhostBadgeEnabled = useExperimentIsEnabled(OptimizelyFlags.SUPERHOST_BADGE);
  const deliveryRevertDecision = useExperimentIsEnabled(
    OptimizelyFlags.REVERT_SETUP_DELIVERY_CHANGE,
  );

  const { selectedCampground } = useSelectedCampground();

  const {
    [ESearchFilters.FILTER_TAGS_SLUG]: filterTagsSlugFromQuery,
    [ESearchFilters.INSTANT_BOOK]: instantBookFromQuery,
    [ESearchFilters.FILTER_FEATURE]: featuresFromQuery,
    [ESearchFilters.DELIVERY_STATIONARY]: deliveryStationaryFromQuery,
  } = useSelector(getQueryParams);

  const modalTriggerState = useMenuTriggerState({
    isOpen: modalIsOpened,
    onOpenChange: open => {
      setModalIsOpened(open);
    },
  });

  const dropdownTriggerState = useMenuTriggerState({
    isOpen: dropdownIsOpened,
    onOpenChange: open => {
      // do not close if autocomplete is open
      if (!open && isAutocompleteOpen) {
        return;
      }
      setIsDropdownOpened(open);
    },
  });

  const isModalOpen = modalTriggerState.isOpen;
  const isDropdownOpen = dropdownTriggerState.isOpen;

  const selectedFilter = useSelector<TRootState, EFilterCategories | null>(
    state => state.search.selectedFilter,
  );
  const isStationaryFilterTriggered = useSelector(getIsStationaryFilterTriggered);

  const getRVTypeCountFromQuery = useCallback(() => {
    const vehicleCount =
      getParamAsString(filtersFromQuery[ESearchFilters.FILTER_TYPE])
        ?.split(',')
        .filter(item => allVehicleStyles.includes(item as TVehicleType)).length || 0;
    return vehicleCount;
  }, [filtersFromQuery]);

  const getPriceCountFromQuery = useCallback(() => {
    const scorePriceRange =
      Number(filtersFromQuery[ESearchFilters.PRICE_MIN]) > 0 ||
      Number(filtersFromQuery[ESearchFilters.PRICE_MAX])
        ? 1
        : 0;

    return scorePriceRange;
  }, [filtersFromQuery]);

  const getPetIsActiveFromQuery = useCallback(() => {
    const featuresFromQuery = filtersFromQuery[ESearchFilters.FILTER_FEATURE];
    const currentFeatures = featuresFromQuery?.toString().split(',') || [];

    return currentFeatures.some(el => el === 'pet_friendly');
  }, [filtersFromQuery]);

  const getMoreCountFromQuery = useCallback(() => {
    // Amenities filters score
    const scoreAmenities =
      amenitiesFromFilterFeatureArray(
        getParamAsString(filtersFromQuery[ESearchFilters.FILTER_FEATURE])?.split(',') || [],
        amenitiesItems,
      ).length || 0;

    // More filters score
    const scoreCancelPolicy =
      getParamAsString(filtersFromQuery[ESearchFilters.CANCEL_POLICY])?.split(',').length || 0;
    const scoreKeywords = filtersFromQuery[ESearchFilters.FILTER_KEYWORDS] ? 1 : 0;
    const scoreLength =
      getParamAsString(filtersFromQuery[ESearchFilters.LENGTH_BETWEEN])?.split(',').length || 0;
    const scoreRules =
      moreFromFilterFeatureArray(
        getParamAsString(filtersFromQuery[ESearchFilters.FILTER_FEATURE])
          ?.split(',')
          .filter(rule => rule !== 'pet_friendly') || [],
        'rv',
      ).length || 0;
    const scoreStarRating = Number(filtersFromQuery[ESearchFilters.SCORE]) > 0 ? 1 : 0;
    const scoreUnlimitedMiles = filtersFromQuery[ESearchFilters.UNLIMITED_MILES] === 'true' ? 1 : 0;
    const scoreUnlimitedGenerator =
      filtersFromQuery[ESearchFilters.UNLIMITED_GENERATOR] === 'true' ? 1 : 0;
    const scoreWeightGreater = Number(filtersFromQuery[ESearchFilters.WEIGHT_GREATER]) > 0 ? 1 : 0;
    const scoreWeightLesser = Number(filtersFromQuery[ESearchFilters.WEIGHT_LESSER]) ? 1 : 0;
    const scoreSeatbelts = Number(filtersFromQuery[ESearchFilters.SEATBELTS]) ? 1 : 0;

    // Make filters score
    const scoreMake = filtersFromQuery[ESearchFilters.FILTER_VEHICLE_MAKE] ? 1 : 0;
    const scoreModels = filtersFromQuery[ESearchFilters.FILTER_VEHICLE_MAKE_MODELS] ? 1 : 0;
    const scoreMakeYearRange =
      filtersFromQuery[ESearchFilters.YEAR_GREATER] || filtersFromQuery[ESearchFilters.YEAR_LESSER]
        ? 1
        : 0;

    return (
      scoreAmenities +
      scoreCancelPolicy +
      scoreKeywords +
      scoreLength +
      scoreRules +
      scoreStarRating +
      scoreUnlimitedGenerator +
      scoreUnlimitedMiles +
      scoreWeightGreater +
      scoreWeightLesser +
      scoreMake +
      scoreModels +
      scoreMakeYearRange +
      scoreSeatbelts
    );
  }, [amenitiesItems, filtersFromQuery]);

  const handleModalDismiss = useCallback(() => {
    setFilterVehiclesShow(1);
    dispatch(setSelectedFilter(null));
    modalTriggerState.close();
  }, [dispatch, modalTriggerState]);

  const handleDropdownDismiss = useCallback(() => {
    setFilterVehiclesShow(1);
    dropdownTriggerState.close();
  }, [dropdownTriggerState]);

  const handleOpenModalFilter = useCallback(
    (filter: EFilterCategories) => {
      setFilterVehiclesShow(1);
      dispatch(setSelectedFilter(filter));
      if (isModalOpen) {
        modalTriggerState.close();
      } else {
        modalTriggerState.open();
      }
    },
    [dispatch, isModalOpen, modalTriggerState],
  );

  const handleOpenDropdownFilter = useCallback(
    (filter: EFilterCategories) => {
      setFilterVehiclesShow(1);
      dispatch(setSelectedFilter(filter));
      if (isDropdownOpen) {
        dropdownTriggerState.close();
      } else {
        dropdownTriggerState.open();
      }
    },
    [dispatch, dropdownTriggerState, isDropdownOpen],
  );

  // Handle filters that act as toggles
  const handleToggleFilter = useCallback(
    (filter: EFilterCategories) => {
      setFilterVehiclesShow(1);

      const filterToUpdate: Record<string, string | string[] | undefined> = {};
      const currentFeatures = featuresFromQuery?.toString().split(',') || [];

      switch (filter) {
        case EFilterCategories.SUPERHOST:
          filterToUpdate[ESearchFilters.FILTER_TAGS_SLUG] = xor(
            getParamAsString(filterTagsSlugFromQuery)
              ?.split(',')
              .map(tag => tag.trim()) || [],
            ['superhost'],
          ).join(',');
          break;
        case EFilterCategories.INSTANT_BOOK:
          filterToUpdate[ESearchFilters.INSTANT_BOOK] = String(instantBookFromQuery != 'true');
          break;
        case EFilterCategories.PET_FRIENDLY:
          if (currentFeatures?.includes('pet_friendly')) {
            const newFeatures = currentFeatures.filter(el => el !== 'pet_friendly').join(',');
            filterToUpdate[ESearchFilters.FILTER_FEATURE] = newFeatures;
          } else {
            currentFeatures.push('pet_friendly');
            filterToUpdate[ESearchFilters.FILTER_FEATURE] = String(currentFeatures.join(','));
          }

          break;
      }

      if (!Object.keys(filterToUpdate).length) {
        return;
      }

      dispatch(
        setFormFilter({ filters: filterToUpdate, filterMethod: EFilterMethod.CHECKBOX }, false),
      );
      dispatch(applySearchFilter(filterToUpdate, false, false, false));
    },
    [dispatch, featuresFromQuery, filterTagsSlugFromQuery, instantBookFromQuery],
  );

  const items = useMemo(() => {
    const baseItems = [
      ...(superhostBadgeEnabled && !selectedCampground
        ? [
            {
              variation: EFilterCategories.SUPERHOST,
              onClick: () => handleToggleFilter(EFilterCategories.SUPERHOST),
              active: /superhost/i.test(getParamAsString(filterTagsSlugFromQuery) || ''),
              title: (
                <>
                  <SuperhostBadgeLightSvg className="mr-1 -ml-1" />
                  {intl.formatMessage({
                    defaultMessage: 'Superhost',
                    id: '4ZK6J+',
                    description: 'Screen size large, filter menu: Superhost',
                  })}
                </>
              ),
              customIcon: <></>,
            },
          ]
        : []),
      {
        variation: EFilterCategories.DELIVERY,
        active: !!deliveryStationaryFromQuery,
        count: 0,
        onClick: () => handleOpenModalFilter(EFilterCategories.DELIVERY),
        selected: isDropdownOpen && selectedFilter === EFilterCategories.DELIVERY,
        title: deliveryRevertDecision
          ? intl.formatMessage({
              defaultMessage: 'Delivery',
              id: 'QQ2w0O',
              description: 'Filter menu: Get it delivered',
            })
          : deliveryStationaryFromQuery === EDeliveryOption.MOVING
            ? intl.formatMessage({
                defaultMessage: 'Delivery',
                id: 'QQ2w0O',
                description: 'Filter menu: Get it delivered',
              })
            : deliveryStationaryFromQuery === EDeliveryOption.STATIONARY
              ? intl.formatMessage({
                  defaultMessage: 'Set up',
                  id: '9YsSnA',
                  description: 'Filter menu: Set up',
                })
              : intl.formatMessage({
                  defaultMessage: 'Pick it up',
                  id: 'IqHysM',
                  description: 'Filter menu: Pick it up',
                }),
      },
      {
        variation: EFilterCategories.TYPE,
        count: getRVTypeCountFromQuery(),
        onClick: () => handleOpenModalFilter(EFilterCategories.TYPE),
        selected: isModalOpen && selectedFilter === EFilterCategories.TYPE,
        title: intl.formatMessage({
          defaultMessage: 'RV type',
          id: 'c/zbfh',
          description: 'Screen size large, filter menu: RV type',
        }),
      },
      {
        variation: EFilterCategories.PRICE,
        count: getPriceCountFromQuery(),
        onClick: isMobile
          ? () => handleOpenModalFilter(EFilterCategories.PRICE)
          : () => handleOpenDropdownFilter(EFilterCategories.PRICE),
        selected: isDropdownOpen && selectedFilter === EFilterCategories.PRICE,
        title: intl.formatMessage({
          defaultMessage: 'Price',
          id: 'PtusVU',
          description: 'Screen size large, filter menu: Price',
        }),
      },
      ...(!selectedCampground
        ? [
            {
              variation: EFilterCategories.INSTANT_BOOK,
              onClick: () => handleToggleFilter(EFilterCategories.INSTANT_BOOK),
              active: instantBookFromQuery === 'true',
              title: (
                <>
                  <GeneralInstantBookIcon className="mr-1 -ml-1 text-lg text-canvas-300" />
                  {intl.formatMessage({
                    defaultMessage: 'Instant book',
                    id: 'IM85Qt',
                    description: 'Screen size large, filter menu: Instant book',
                  })}
                </>
              ),
              customIcon: <></>,
            },
          ]
        : []),
      {
        variation: EFilterCategories.PET_FRIENDLY,
        onClick: () => handleToggleFilter(EFilterCategories.PET_FRIENDLY),
        active: getPetIsActiveFromQuery(),
        title: intl.formatMessage({
          defaultMessage: 'Pet friendly',
          id: 'JLo45i',
          description: 'Screen size large, filter menu: Pet Friendly',
        }),
        customIcon: <></>,
      },
      {
        variation: EFilterCategories.MORE,
        count: getMoreCountFromQuery(),
        onClick: () => handleOpenModalFilter(EFilterCategories.MORE),
        selected: isModalOpen && selectedFilter === EFilterCategories.MORE,
        title: intl.formatMessage({
          defaultMessage: 'More',
          id: 'iZkxSL',
          description: 'Screen size large, filter menu: More',
        }),
      },
    ].filter(item => {
      // Based on the incoming promotional filter conditions, hide certain items from the filter.
      return !hideFilter(item.variation);
    });

    // On mobile, sort active filters to the front
    if (isMobile) {
      return baseItems.sort((a, b) => {
        const aIsSelected = a.active || (a.count ?? 0) > 0;
        const bIsSelected = b.active || (b.count ?? 0) > 0;

        if (aIsSelected && !bIsSelected) return -1;
        if (!aIsSelected && bIsSelected) return 1;
        return 0;
      });
    }

    return baseItems;
  }, [
    superhostBadgeEnabled,
    filterTagsSlugFromQuery,
    isDropdownOpen,
    selectedFilter,
    deliveryStationaryFromQuery,
    intl,
    getRVTypeCountFromQuery,
    isModalOpen,
    getPriceCountFromQuery,
    isMobile,
    selectedCampground,
    instantBookFromQuery,
    getPetIsActiveFromQuery,
    getMoreCountFromQuery,
    handleOpenModalFilter,
    handleOpenDropdownFilter,
    handleToggleFilter,
    hideFilter,
    deliveryRevertDecision,
  ]);

  useEffect(() => {
    if (isStationaryFilterTriggered) {
      handleOpenModalFilter(EFilterCategories.DELIVERY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStationaryFilterTriggered]);

  useEffect(() => {
    const { openStationaryDeliveryModal, ...restQuery } = router.query;

    if (openStationaryDeliveryModal === 'true' && !isModalOpen) {
      handleOpenModalFilter(EFilterCategories.DELIVERY);
    }

    // clean up stationary modal trigger param
    if (openStationaryDeliveryModal) {
      router.replace({ query: restQuery }, undefined, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <>
      {/* Renders menu pills on desktop and mweb */}
      <FilterPills items={items} setFieldElement={setFieldElement} />

      {/* Handles filter content */}
      <FiltersPillsContentContainer
        isDropdownOpen={isDropdownOpen}
        isModalOpen={isModalOpen}
        dropdownTriggerState={dropdownTriggerState}
        triggerRef={triggerRef}
        handleDropdownDismiss={handleDropdownDismiss}
        handleModalDismiss={handleModalDismiss}
      />
    </>
  );
};

export default FilterPillsContainer;
